import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';

export type CalculatePaymentDetails = ({
  coupon,
  removeCoupon,
  email,
}: {
  coupon?: string;
  removeCoupon?: boolean;
  email?: string;
}) => void;

export function createCalculatePaymentDetailsAction({
  actionFactoryParams,
  errorHandlers,
}: {
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>;
  errorHandlers: ErrorHandlers;
}): CalculatePaymentDetails {
  return async ({ coupon, removeCoupon, email }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi, reportError } = actionFactoryParams.context;
    const {
      formInputs: { numberOfParticipants },
      slotAvailability,
      service,
    } = state;
    const { id: serviceId, rate } = service;
    const slot = slotAvailability?.slot!;
    const couponCode = removeCoupon
      ? undefined
      : coupon || state.couponInfo.appliedCoupon?.couponCode;
    try {
      const paymentsDetails = await formApi.getPaymentsDetails({
        slot,
        numberOfParticipants,
        rate,
        serviceId,
        couponCode,
        email,
      });
      const appliedCoupon = paymentsDetails?.couponDetails;
      const finalPrice = paymentsDetails?.finalPrice;
      setState({
        couponInfo: {
          ...state.couponInfo,
          appliedCoupon,
        },
        paymentDetails: {
          ...state.paymentDetails,
          minCharge: finalPrice?.downPayAmount
            ? Number(finalPrice.downPayAmount)
            : state.paymentDetails.minCharge,
          totalPrice: finalPrice?.amount
            ? Number(finalPrice?.amount)
            : state.paymentDetails.price,
        },
      });
    } catch (error) {
      errorHandlers.addError(error);
      reportError(error);
    }
    setState({
      status: FormStatus.IDLE,
    });
  };
}
