import { ControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApi } from '../../api/FormApi';
import { TFunction } from '../../types/types';
import { FormBiLogger } from '../bi/biLoggerFactory';

export type FormContext = {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
  biLogger?: FormBiLogger;
  reportError: ReportError;
};

export const createFormContext = ({
  t,
  settings,
  wixSdkAdapter,
  formApi,
  biLogger,
  reportError,
}: FormContext): FormContext => {
  return {
    t,
    settings,
    wixSdkAdapter,
    formApi,
    biLogger,
    reportError,
  };
};
